export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9')];

export const server_loads = [0];

export const dictionary = {
	"/(centered)": [4,[2]],
	"/app": [7,[3]],
	"/bitski-callback": [8],
	"/(centered)/create/1": [~5,[2]],
	"/(centered)/create/2": [~6,[2]],
	"/twitter-callback": [~9]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};